@font-face {
	font-family: 'Varta Regular';
	src: url('assets/fonts/Varta-Regular.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

* {
	outline: none;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.App {
	display: grid;
	place-items: center;
	padding-block: 1rem 0;
	font-family: 'Varta Regular';
	text-align: center;
}

.bmi-score {
	height: 2.3rem;
	color: rgb(0, 110, 255);
	font-size: 3rem;
	font-weight: 900;
	text-shadow: 4px 3px 5px rgb(75, 83, 96);
}

input {
	margin-inline: auto;
}

.bmi-thumb {
	display: block;
	appearance: none;
	width: 24.3rem;
	margin-block: 1rem 0;
}

.bmi-thumb:first-of-type {
	margin-block: 2rem 0;
}

/* linear meter */
.underweight,
.appropriate,
.overweight {
	display: inline-block;
	box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 15px -3px,
		rgba(0, 0, 0, 0.7) 0px 4px 6px -2px;
	width: 8rem;
	height: 1rem;
}

.underweight,
.overweight {
	background-color: #f3595b;
}

.underweight {
	border-radius: 10px 0 0 10px;
}

.appropriate {
	margin-inline: 0.2rem;
	background-color: #30cc90;
}

.overweight {
	border-radius: 0 10px 10px 0;
}

.normal-category {
	margin-inline: 4rem;
}

/* Calc input */
.calculation-label {
	display: block;
	margin-block: 3rem 0;
	margin-inline: auto;
	font-size: 1.5rem;
	width: 25rem;
}

.calculation-input {
	border: 1px solid #e2e2e2;
	display: block;
	width: 8rem;
	margin-block: 1rem;
	font-size: 2rem;
	text-align: center;
}

/* Thumbs */
.bmi-thumb-triangle,
.bmi-thumb-scale {
	margin-block-end: 0.1rem;
}

.bmi-thumb-triangle::-moz-range-thumb {
	-moz-appearance: none;
	border: 0;
	clip-path: polygon(0 0, 100% 0, 50% 100%);
	width: 1.7rem;
	height: 1rem;
	background-color: rgb(75, 83, 96);
}

.bmi-thumb-triangle::-webkit-slider-thumb {
	-moz-appearance: none;
	border: 0;
	-webkit-clip-path: polygon(0 0, 100% 0, 50% 100%);
	clip-path: polygon(0 0, 100% 0, 50% 100%);
	width: 1.7rem;
	height: 1rem;
	background-color: rgb(75, 83, 96);
}

.bmi-thumb-scale::-moz-range-thumb {
	-moz-appearance: none;
	border: 0;
	width: 1.5rem;
	height: 1.5rem;
	background: url('./assets/img/meter-400x360.png') no-repeat center / 100% 100%;
}

.bmi-thumb-scale::-webkit-slider-thumb {
	-webkit-appearance: none;
	border: 0;
	width: 1.5rem;
	height: 1.5rem;
	background: url('./assets/img/meter-400x360.png') no-repeat center / 100% 100%;
}

.sad,
.happy {
	margin-block-end: 0.3rem;
}

.sad::-moz-range-thumb {
	-moz-appearance: none;
	border: 0;
	width: 1.5rem;
	height: 1.5rem;
	background: url('./assets/img/sad.jpg') no-repeat center / 100% 100%;
}

.sad::-webkit-slider-thumb {
	-webkit-appearance: none;
	border: 0;
	width: 1.5rem;
	height: 1.5rem;
	background: url('./assets/img/sad.jpg') no-repeat center / 100% 100%;
}

.happy::-moz-range-thumb {
	-moz-appearance: none;
	border: 0;
	width: 1.5rem;
	height: 1.5rem;
	background: url('./assets/img/happy.jpg') no-repeat center / 100% 100%;
}

.happy::-webkit-slider-thumb {
	-webkit-appearance: none;
	border: 0;
	width: 1.5rem;
	height: 1.5rem;
	background: url('./assets/img/happy.jpg') no-repeat center / 100% 100%;
}

/* Cat meter */
#cat-meter {
	display: flex;
	margin-block: 4rem;
}

.illustration {
	width: 8rem;
	height: 8rem;
}

.blurred {
	filter: blur(1rem);
	transition: filter 300ms;
}

#footer {
	width: 100%;
	padding-block: 2rem;
	background: rgb(255, 255, 255);
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(75, 83, 96, 0.3029412448573179) 100%
	);
}

/* helper */
.hidden-visually {
	position: absolute;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	overflow: hidden;
	width: 1px;
	height: 1px;
	white-space: nowrap;
}
